// Function to handle intersection observer callback
function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            // Video is in viewport - play it
            entry.target.play();
        } else {
            // Video is out of viewport - pause it
            entry.target.pause();
            entry.target.currentTime = 0;
        }
    });
}

// Wait for DOM to be fully loaded
document.addEventListener('DOMContentLoaded', function () {
    // Get the video element
    const video = document.getElementById('demoVideo');

    // Create options for the observer
    const options = {
        root: null, // use viewport
        rootMargin: '0px', // no margin
        threshold: 0.5 // trigger when 50% of the video is visible
    };

    // Create and start the intersection observer
    const observer = new IntersectionObserver(handleIntersection, options);

    // Start observing the video element
    if (video) {
        observer.observe(video);
    }

    // Optional: Handle page visibility changes
    document.addEventListener('visibilitychange', function () {
        if (document.hidden) {
            video.pause();
        } else {
            // Only play if video is in viewport
            const entry = observer.takeRecords()[0];
            if (entry && entry.isIntersecting) {
                video.play();
            }
        }
    });

    const loadingIndicator = document.getElementById('loadingIndicator');
    video.addEventListener('loadeddata', () => {
        loadingIndicator.style.display = 'none';
    });

    AOS.init({
        duration: 800,
        easing: 'ease-out',
        once: true
    });
});
